.btn {
	transition: 0.2s;
}

.btn:active {
	box-shadow: 1px 1px 8px rgba(0, 0, 0, 31%);
	border: inherit;
}

.btn-gradient {
	background: linear-gradient(90deg, #fc6373 0%, #fe9548 100%);
	color: #fff;
}

.btn-gradient:hover {
	background: linear-gradient(90deg, #fff 0%, #fff 100%);
	color: var(--bs-primary) !important;
}

.btn-outline-gradient {
	background: linear-gradient(90deg, #fc637300 0%, #fe954800 100%);
	color: var(--bs-primary);
	border: 1px solid var(--bs-primary);
}

.btn-outline-gradient:hover {
	background: linear-gradient(90deg, #fc6373 0%, #fe9548 100%);
	color: white !important;
	border: 1px solid transparent !important;
}
