.f-google-san {
	font-family: "Google Sans", var(--bs-body-font-family);
}

.f-merienda {
	font-family: "Merienda One", var(--bs-body-font-family);
}

.f-quicksand {
	font-family: "Quicksand", var(--bs-body-font-family);
}

.f-crimson-pro {
	font-family: "Crimson Pro", var(--bs-body-font-family);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bolder;
	font-family: "Google Sans", var(--bs-body-font-family);
}

.text-gradient {
	background-clip: text !important;
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent;
	background: var(--bs-gradient);
}

.text-outline-primary {
	color: transparent;
	-webkit-text-stroke: 2px var(--bs-primary);
}

.text-outline-secondary {
	color: transparent;
	-webkit-text-stroke: 2px var(--bs-secondary);
}

.text-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.fs-sub {
	font-size: calc(1rem * 6 / 7);
}

small {
	font-size: calc(1rem * 5 / 7);
}
