@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.fadeIn {
	animation-name: fadeIn;
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInDown {
	animation-name: fadeInDown;
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUp {
	animation-name: fadeInUp;
}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInLeft {
	animation-name: fadeInLeft;
}

@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInRight {
	animation-name: fadeInRight;
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.fadeOut {
	animation-name: fadeOut;
}

@keyframes fadeOutDown {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
}

.fadeOutDown {
	animation-name: fadeOutDown;
}

@keyframes fadeOutLeft {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}
}

.fadeOutLeft {
	animation-name: fadeOutLeft;
}

@keyframes fadeOutRight {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}
}

.fadeOutRight {
	animation-name: fadeOutRight;
}

@keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
}

.fadeOutUp {
	animation-name: fadeOutUp;
}

/* Helpers */

.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
}
