@import url("https://fonts.googleapis.com/css2?family=Merienda+One&family=Quicksand:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital@0;1&display=swap");
@import url("../fonts/google-sans");

/* Config `rem` */
html {
	font-size: 14px;
}

/* Modify Bootstrap */
:root {
	--bs-body-font-family: "Google Sans", var(--bs-font-sans-serif);
	--bs-body-text-align: justify;
}

:root,
[data-bs-theme="light"] {
	--bs-body-bg: #f8f8f8;
	--bs-body-bg-rgb: var(--bs-light-rgb);
	--bs-primary: #fe9548;
	--bs-primary-rgb: 254, 149, 72;
	--bs-secondary: #48b1fe;
	--bs-secondary-rgb: 72, 177, 254;
	--bs-info: #adadad;
	--bs-info-rgb: 173, 173, 173;
	--bs-gradient: linear-gradient(90deg, #fc6373, #fe9548);
}

img {
	object-fit: cover;
}
