.before {
  opacity: 1;
  border-radius: 50%;
  background: var(--bs-primary);
}

.after {
  opacity: 0;
  border-width: 0;
  border-color: #eba919;
}
