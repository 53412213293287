@media (min-width: 992px) {
	.h-lg-25 {
		height: 25% !important;
	}

	.h-lg-50 {
		height: 50% !important;
	}

	.h-lg-75 {
		height: 75% !important;
	}

	.h-lg-100 {
		height: 100% !important;
	}
}
